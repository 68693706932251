import React from 'react';
import { Link } from 'gatsby';

// Components
import JaLayout from '../../components/JaLayout';

const Privacy = () => {
  return (
    <JaLayout>
      <section id="page_bn" class="pb-1" style={{ backgroundColor: '#efefef', marginTop: '-16px' }}>
        <div class="container">
          <div id="breadcrumb" class="">
            <div class="container radius" style={{ backgroundColor: ' #7aacd2' }}>
              <div class="col-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb small text-white pt-3 pb-2 bg-js">
                    <li class="breadcrumb-item">
                      <Link to="/ja" style={{ color: '#fff' }}>
                        ホーム
                      </Link>
                    </li>
                    <li class="breadcrumb-item">個人情報保護方針</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="case" class="my-3">
        <div class="container ">
          <div class="row">
            <div class="col-12 text-left">
              <p class="h3">
                <strong>個人情報保護方針</strong>
              </p>
              <p>更新日: 2020年10月</p>
            </div>
            <div id="" class="col-12">
              <hr />
            </div>

            <div class="col-12">
              <p>
                ようこそ「株式会社チンシン」のウェブサイトへ（以下、本サイトと言います）。本サイトのサービスと情報を安心してご利用いただけるよう、本サイトのご利用規約（著作権・リンク・個人情報の取り扱い等）について説明いたします。
                本サイトでは、お客様からご提供いただいた個人情報を尊重し、プライバシーの保護に努めております。
              </p>
              <h5>
                <strong> 一、プライバシーの情報保護法の適用範囲 </strong>
              </h5>
              <p>
                本サイトのサービスの使用時に収集された個人情報をどのように処理するかなどを説明いたします。プライバシーの情報保護法の適用範囲について本サイト以外の関連ウェブサイトには適用されません。また、本サイト以外の委託業務もしくは管理責任者を置いても適用されません。{' '}
              </p>
              <h5>
                二、個人データの収集、処理、使用方法
                <br />
              </h5>
              <ul>
                <li>
                  本サイトにアクセスし、提供するサービスを利用する場合、サービスを利用することと認められるので必要な個人データの提供を求め、特定の目的の範囲内で個人データを処理および使用します。利用者による書面での同意がない限り、再生、複写、複製、売却、再売却、その他商業目的の利用をすることができません。
                </li>
                <li>
                  本サイトは問い合わせ欄、アンケートなどのインタラクティブ機能を使用する際に、ご提供いただいた名前、メールアドレス、連絡先情報および利用時間のデータが保存されます。
                </li>
                <li>
                  本サイトサービスを改善するため、参照ベースとし本サイトを閲覧する時、サーバーは接続されたデバイスのIPアドレス、利用時間、使用されたブラウザー、ブラウジングおよびクリックデータレコードと関連するアクションなど自動で記録されます。このデータは内部使用のためのものであり、公開されることはありません。
                </li>
                <li>
                  正確なサービスを提供するために、収集したアンケートに基づいて統計・分析を行い、分析結果の統計データや説明文を提示します。社内調査のほか、必要に応じて統計データや説明文を公開しますが、個人情報は含まれません。
                </li>
              </ul>
              <h5>
                <strong>三、データ保護 </strong>
              </h5>
              <ul>
                <li>
                  本サイトは、サイト自身と厳格に個人情報データを保護するためにファイアウォール、アンチウイルスシステム、必要な安全保護対策などのさまざまなセキュリティを整えています。権限のある管理者のみが利用者の個人情報を読むことが可能です。データなどを管理する管理者は機密保持契約に署名し、機密保持義務に違反した場合は、関連する法律によって罰せられます。{' '}
                </li>
                <li>
                  業務ニーズによって他の機関にサービスの提供を委託する必要のある場合、本サイトでは、機密保持義務を遵守し、確実に守るために必要な検査手順を実行することも厳密に要求します。
                </li>
              </ul>
              <h5>
                <strong>四、本サイト上の外部リンク </strong>
              </h5>
              <p>
                本サイトは他のウェブサイトへの外部リンクを提供し、それを通して他のウェブサイトに入ることができます。但し、本サイト以外のウェブサイトはプライバシーの情報保護法の適用範囲に該当しません。リンク先のウェブサイトのプライバシーの情報保護法を参照することが必要です。{' '}
              </p>
              <h5>
                <strong>五、 第三者に対する個人情報の共有に関するポリシー </strong>
              </h5>
              <p>
                利用者の個人情報を個人、団体、民間企業または公的機関に提供、交換、貸与、または販売することは決してありませんが、法的根拠あるいは契約上の義務のある人はこれに限りません。但し、以下の場合は該当しません。{' '}
              </p>
              <ul>
                <li>利用者による書面での同意。</li>
                <li>法律に明確に規定すること。</li>
                <li>命、身体、自由又は財産に対する現在の危難を避けるため。</li>
                <li>
                  公共の利益に基づく統計的または学術的研究には、公的機関または学術研究機関との協力が必要であり、データはプロバイダーによって処理または収集され、その開示方法に従って当事者を特定することはできません。
                </li>
                <li>
                  ウェブサイトを利用する時、利用規約に違反することもしくはウェブサイトや他のユーザーの権利を妨害することによって、誰かに損害を与えて必要のある場合は、ウェブサイト管理者は、識別、連絡、または法的措置を取る目的で利用者の個人情報を分析して開示します。{' '}
                </li>
                <li>利用者の権利を助長すること。 </li>
                <li>
                  本サイトは利用者の個人データの収集、処理または使用することにあたって委託するベンダーあるいは個人に対して監督および管理の義務を果たします。{' '}
                </li>
              </ul>
              <h5>
                <strong>六、Cookie之使用 </strong>
              </h5>
              <p>
                最高のサービスを提供するために、本サイトは、利用者のパソコンにCookieを配置しアクセスします。Cookieに書き込みを受け入れたくない場合は、使用のブラウザー機能のプライバシーレベルを「高」に設定するようにしてください。そうしますと書き込みを拒否することはできますが、ウェブサイトの一部の機能が正常に実行できない可能性があります。{' '}
              </p>
              <h5>
                <strong>七、 プライバシー保護ポリシーの修正 </strong>
              </h5>
              <p>
                本サイトはニーズに応じてプライバシー保護ポリシーの修正を伴いますは、改訂後の条件はウェブサイトで公開します。
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </section>
    </JaLayout>
  );
};

export default Privacy;
